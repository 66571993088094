import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
class Maquinaria extends Component{
    render(){

        let data = [
            {pageLink: 'trituracion', img: 'trituradora.jpg', iconClass: 'flaticon-002-welding', serviceTitle: 'Trituración', serviceSubtitle: '. Es un proceso de reducción de tamaño desde que sale de la mina hasta ser entregado a la alimentación del molino primario se realiza en máquinas conocidas como trituradoras que son por el tamaño de alimentación y descarga de diferentes diseños.'},
            {pageLink: 'cribado', img: 'cribado.jpg', iconClass: 'flaticon-049-container', serviceTitle: 'Cribado', serviceSubtitle: '	Cribado: Es el proceso de clasificar por tamaños el producto de las trituradoras en maquinas que cuentan con mecanismos con movimientos oscilatorios o desplazamientos sobre telas metálicas o de hule con diferentes aberturas.'},
            {pageLink: 'molinos', img: 'molinos.jpg', iconClass: 'flaticon-015-cart', serviceTitle: 'Molinos', serviceSubtitle: '	Molienda es el proceso de reducción de tamaño después de la etapa de trituración que va desde los 12 milímetros hasta los 45 micrones que demanda la liberación del mineral a concentrar o beneficiar, se realiza en húmedo que es lo más común o en seco por el tipo de elemento con que se hace la reducción de tamaño.'},
            {pageLink: 'Concentracion', img: 'flotacion.jpg', iconClass: 'flaticon-010-tank-1', serviceTitle: 'Concentración', serviceSubtitle: 'Es el proceso de separación del mineral del que no es de interes ganga o colas y se realiza en equipos de concentración '},
            {pageLink: 'Manejo-de-Materiales', img: 'bomba.jpg', iconClass: 'flaticon-017-pump', serviceTitle: 'Manejo de Materiales', serviceSubtitle: 'Es la operación de transportar el mineral de un punto a otro de la planta para realizar el proceso de beneficio, este se realiza en seco y en húmedo.'},
            {pageLink: 'Espesamiento-y-Filtrado', img: 'filtrado1.png', iconClass: 'flaticon-026-mechanism', serviceTitle: 'Espesamiento y Filtrado', serviceSubtitle: 'Son los equipos mediante el cual se recupera el agua y las soluciones que contienen los reactivos utilizadas para concentrar el mineral.'},
            {pageLink: 'Secado-de-concentrados', img: 'filtrado1.png', iconClass: 'flaticon-026-mechanism', serviceTitle: 'Secado de concentrados', serviceSubtitle: 'Es el proceso de aplicar calor a los concentrados de mineral para eliminar la humedad residual para su venta o fundición se realiza.'},
            {pageLink: 'Equipo-de-Laboratorio', img: 'filtrado1.png', iconClass: 'flaticon-026-mechanism', serviceTitle: 'Equipo de Laboratorio', serviceSubtitle: 'El proceso de beneficio requiere una serie de pruebas que indiquen los contenidos desde entrada del mineral hasta obtener el concentrado valioso, así como la recuperación en peso del proceso y el control del mismo para eso se requiere.'},
            {pageLink: 'refacciones', img: 'refacciones.jpg', iconClass: 'flaticon-016-gear', serviceTitle: 'Refacciones y consumibles', serviceSubtitle: 'Los equipos para el beneficio de los minerales normalmente se desgasta y requieren el remplazo. Cada equipo tiene una dimensión definida o numero de parte para ser substituido.'},
            {pageLink: 'plantas', img: 'plantas.jpg', iconClass: 'flaticon-018-power-tower', serviceTitle: 'Plantas Completas', serviceSubtitle: 'En el desarrollo de los procesos de beneficio de mineral en la pequeña y mediana industria se parte básicamente de un denuncio y una extracción del mineral y se requiere el conocimiento necesario para desarrollar el proceso de beneficio promival ofrece como servicio el desarrollo.'}
          
        ];

        let Datalist = data.map((val, i) => {
            return(
                <div className="col-lg-4 col-md-6 col-12 section-space--bottom--30" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}>
                            <img src={`assets/img/service/${val.img}`} className="img-fluid" alt="Service Grid" />
                        </a>
                        </div>
                        <div className="icon">
                        <i className={val.iconClass} />
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <h3 className="title">
                        <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}>{val.serviceTitle}</a>
                        </h3>
                        <p className="subtitle">{val.serviceSubtitle}</p>
                        <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`} className="see-more-link">VER MÁS</a>
                    </div>
                    </div>
                </div>
            )
        });

        return(
            <div>

                {/* Navigation bar */}
                <NavBar/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Maquinaria</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>Maquinaria</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                {/*====================  service page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                {/*Service section start*/}
                <div className="service-section">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="service-item-wrapper">
                            <div className="row">
                                {Datalist}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/*Service section end*/}
                </div>

                {/*====================  End of service page content  ====================*/}

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}

export default Maquinaria;