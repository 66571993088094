import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import NavBarEn from '../components/NavBarEn';
import FooterEn from '../components/FooterEn';
import MobileMenuEn from '../components/MobileMenuEn';
class Equipment extends Component{
    render(){

        let data = [
            {pageLink: 'Crusher', img: 'trituradora.jpg', iconClass: 'flaticon-002-welding', serviceTitle: 'Crusher', serviceSubtitle: 'It is a process of size reduction from when it leaves the mine until it is delivered to the primary mill feed, it is carried out in machines known as crushers that are, due to the feed and discharge size, of different designs.'},
            {pageLink: 'Vibrating-Screen', img: 'cribado.jpg', iconClass: 'flaticon-049-container', serviceTitle: 'Vibrating Screen', serviceSubtitle: 'It is the process of classifying by size the product of the crushers in machines that have mechanisms with oscillatory movements or displacements on metallic or rubber fabrics with different openings.'},
            {pageLink: 'molinos', img: 'molinos.jpg', iconClass: 'flaticon-015-cart', serviceTitle: 'Mill', serviceSubtitle: 'It is the process of size reduction after the crushing stage that goes from 12 millimeters to 45 microns that demands the release of the mineral to be concentrated or benefited, it is carried out wet, which is the most common or dry, due to the type of element with which the size reduction is made.'},
            {pageLink: 'Concentracion', img: 'flotacion.jpg', iconClass: 'flaticon-010-tank-1', serviceTitle: 'Concentration', serviceSubtitle: 'It is the process of separating the mineral from which is not of interest gangue or tails and is carried out in concentration equipmen.'},
            {pageLink: 'Manejo-de-Materiales', img: 'bomba.jpg', iconClass: 'flaticon-017-pump', serviceTitle: 'Material handling', serviceSubtitle: 'It is the operation of transporting the mineral from one point to another in the plant to carry out the beneficiation process, this is carried out dry and wet.'},
            {pageLink: 'Espesamiento-y-Filtrado', img: 'filtrado1.png', iconClass: 'flaticon-026-mechanism', serviceTitle: 'Thickening and Filtering', serviceSubtitle: 'They are the equipment by which the water is recovered and the solutions that contain the reagents used to concentrate the mineral.'},
            {pageLink: 'Secado-de-concentrados', img: 'filtrado1.png', iconClass: 'flaticon-026-mechanism', serviceTitle: 'Concentrate drying', serviceSubtitle: 'It is the process of applying heat to mineral concentrates to eliminate residual moisture for sale or smelting is carried out.'},
            {pageLink: 'Equipo-de-Laboratorio', img: 'filtrado1.png', iconClass: 'flaticon-026-mechanism', serviceTitle: 'Laboratory equipment', serviceSubtitle: 'The beneficiation process requires a series of tests that indicate the contents from the entrance of the mineral to obtaining the valuable concentrate, as well as the recovery in weight of the process and its control.'},
            {pageLink: 'refacciones', img: 'refacciones.jpg', iconClass: 'flaticon-016-gear', serviceTitle: 'Spare parts and consumables', serviceSubtitle: 'Equipment for the benefit of minerals usually wears out and requires replacement. Each equipment has a defined dimension or part number to be replaced.'},
            {pageLink: 'plantas', img: 'plantas.jpg', iconClass: 'flaticon-018-power-tower', serviceTitle: 'Complete Plants', serviceSubtitle: 'The development of mineral beneficiation processes in small and medium-sized industries basically starts from a complaint and an extraction of the mineral and the necessary knowledge is required to develop the beneficiation process. Promival offers development as a service.'}
          
        ];

        let Datalist = data.map((val, i) => {
            return(
                <div className="col-lg-4 col-md-6 col-12 section-space--bottom--30" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}>
                            <img src={`assets/img/service/${val.img}`} className="img-fluid" alt="Service Grid" />
                        </a>
                        </div>
                        <div className="icon">
                        <i className={val.iconClass} />
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <h3 className="title">
                        <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}>{val.serviceTitle}</a>
                        </h3>
                        <p className="subtitle">{val.serviceSubtitle}</p>
                        <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`} className="see-more-link">MORE</a>
                    </div>
                    </div>
                </div>
            )
        });

        return(
            <div>

                {/* Navigation bar */}
                <NavBarEn/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Equipment</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>Equipment</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                {/*====================  service page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                {/*Service section start*/}
                <div className="service-section">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="service-item-wrapper">
                            <div className="row">
                                {Datalist}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/*Service section end*/}
                </div>

                {/*====================  End of service page content  ====================*/}

                {/* Footer */}
                <FooterEn/>

                {/* Mobile Menu */}
                <MobileMenuEn/>

            </div>
        )
    }
}

export default Equipment;