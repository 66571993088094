import React, { Component } from 'react';

class Sidebar extends Component{
    render(){

        let data = [
            {sidebarListLink: "trituracion", sidebarListIcon: 'flaticon-015-cart', sidebarListText: 'Trituracion'},
            {sidebarListLink: "cribado", sidebarListIcon: 'flaticon-010-tank-1', sidebarListText: 'Cribado'},
            {sidebarListLink: "molinos", sidebarListIcon: 'flaticon-002-welding', sidebarListText: 'Molienda'},
            {sidebarListLink: "flotacion", sidebarListIcon: 'flaticon-004-walkie-talkie', sidebarListText: 'Flotación'},
            {sidebarListLink: "bombeo", sidebarListIcon: 'flaticon-042-monitor', sidebarListText: 'Bombeo'},
            {sidebarListLink: "filtracion", sidebarListIcon: 'flaticon-050-boxes', sidebarListText: 'Filtrado de Minerales'},
            {sidebarListLink: "refacciones", sidebarListIcon: 'flaticon-050-boxes', sidebarListText: 'Refacciones'},
            {sidebarListLink: "plantas", sidebarListIcon: 'flaticon-018-power-tower', sidebarListText: 'Plantas Completas'},
        ];

        let Datalist = data.map((val, i)=>{
            return(
                <li key={i}><a href={val.sidebarListLink}><i className={val.sidebarListIcon} />{val.sidebarListText}</a></li>
            )
        })

        return(
            <div>
                <div className="sidebar-wrapper">
                    <div className="sidebar">
                        <h3 className="sidebar-title">Maquinaria</h3>
                        <ul className="sidebar-list">
                            {Datalist}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default Sidebar;