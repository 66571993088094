import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class ServiceTabTwoEn extends Component{

    render(){
        
        /* service tab menu */
        let serviceTabMenuData = [
            {iconName: 'flaticon-002-welding', tabMenuName: 'Crusher'},
            {iconName: 'flaticon-004-walkie-talkie', tabMenuName: 'Vibrating Screen'},
            {iconName: 'flaticon-015-cart', tabMenuName: 'Mill'},
            {iconName: 'flaticon-010-tank-1', tabMenuName: 'Flotation'}
        ];

        let serviceTabMenuDatalist = serviceTabMenuData.map((val, i)=>{
            return(
                <Tab key={i}>  <span className="icon"><i className={val.iconName} /></span> <span className="text">{val.tabMenuName}</span></Tab>
            )
        });

        
        /* service tab content */
        
        let serviceTabContentData = [
            {bgUrl: "trituradora.jpg", contentTitle: 'Crusher', contentDesc: 'Used in the intermediate, fine and ultrafine crushing processs, is researched and developed by adopting the world´s most advanced crushing technology.', serviceLink: 'trituracionEn'},
            {bgUrl: "cribado.jpg", contentTitle: 'Vibrating Screen', contentDesc: 'Moves on a cicular motion. It is a multi-layer and high efficient vibrating screen upgraded and improved according to the customer´s site usage and maintenance experience.', serviceLink: 'cribado'},
            {bgUrl: "molinos.jpg", contentTitle: 'Mill', contentDesc: 'It is the key milling equipment after material has been crushed. It´s widely used in cement. Silicate and new construction material.', serviceLink: 'molinos'},
            {bgUrl: "flotacion.jpg", contentTitle: 'Flotation', contentDesc: 'Flotation machine is used for extracting ofmetals like gold, argent, iron, lead, zinc, molybdenum, nuckel and aluminum.', serviceLink: 'flotacion'}
        ];

        let serviceTabContentDatalist = serviceTabContentData.map((val, i)=>{
            return(
                <TabPanel key={i}>
                    <div className="service-tab__single-content-wrapper" style={{ backgroundImage: `url(assets/img/backgrounds/${val.bgUrl})` }}>
                        <div className="service-tab__single-content">
                            <h3 className="service-tab__title">{val.contentTitle}</h3>
                            <p className="service-tab__text">{val.contentDesc}</p>  
                            <a href={`${process.env.PUBLIC_URL}/${val.serviceLink}`} className="see-more-link">More</a>
                        </div>
                    </div>
                </TabPanel>
            )
        });

        return(
            <div>

                {/*====================  service tab area ====================*/}
                <div className="service-tab-area section-space--inner--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* section title */}
                                <div className="section-title-area text-center section-space--bottom--50">
                                    <h2 className="section-title">Equipment       </h2>
                                    <p className="section-subtitle">We have the latest technology in mining equipment.</p>
                                </div>

                            </div>
                            <div className="col-lg-12">
                                {/* service tab wrapper */}
                                
                                <div className="service-tab-wrapper">
                                <Tabs>
                                    <div className="row no-gutters">
                                        <div className="col-md-4">
                                            <TabList>
                                                <div className="service-tab__link-wrapper">
                                                    {serviceTabMenuDatalist}
                                                </div>
                                            </TabList>
                                        </div>

                                        <div className="col-md-8">
                                            {serviceTabContentDatalist}
                                        </div>
                                    </div>
                                </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of service tab area  ====================*/}
            </div>
        )
    }
}

export default ServiceTabTwoEn;