import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class MobileMenuEn extends Component{

    state = {
        active : false
    }

    toggleMobileMenu = () => {
        this.setState({
            active: !this.state.active
        })
    }

    componentDidMount(){
        var offCanvasNav = document.getElementById('offcanvas-navigation');
        var offCanvasNavSubMenu = offCanvasNav.querySelectorAll('.sub-menu');

        for (let i = 0; i<offCanvasNavSubMenu.length; i++){
           offCanvasNavSubMenu[i].insertAdjacentHTML("beforebegin", "<span class='menu-expand'><i></i></span>");
        }

        var menuExpand = offCanvasNav.querySelectorAll('.menu-expand');
        var numMenuExpand = menuExpand.length;

        function sideMenuExpand() {

            if(this.parentElement.classList.contains('active') === true) {
                this.parentElement.classList.remove('active');
            } else {
                for (let i = 0; i < numMenuExpand; i++) {
                    menuExpand[i].parentElement.classList.remove('active');
                }
                this.parentElement.classList.add('active');
            }
        }

        for (let i = 0; i < numMenuExpand; i++) {
            menuExpand[i].addEventListener("click", sideMenuExpand);
        }
    }


    render(){
        return(
            <div>
                {/*=======  offcanvas mobile menu  =======*/}
                <div className= {`offcanvas-mobile-menu ${this.state.active ? 'active': ''} `} id="mobile-menu-overlay">
                    <a href="#/" className="offcanvas-menu-close" id="mobile-menu-close-trigger" onClick={this.toggleMobileMenu}>
                        <i className="ion-android-close" />
                    </a>
                    <div className="offcanvas-wrapper">
                        <div className="offcanvas-inner-content">
                            <nav className="offcanvas-navigation" id="offcanvas-navigation">
                            <ul>
                                <li className="menu-item-has-children">
                                <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                                    
                                </li>
                                <li><Link to={`${process.env.PUBLIC_URL}/us`}>Us</Link></li>
                                <li className="menu-item-has-children">
                                    <Link to={`${process.env.PUBLIC_URL}/Equipment`}>Equipmnent</Link>
                                    <ul className="sub-menu">
                                        <li><Link to={`${process.env.PUBLIC_URL}/trituracion`}>Crusher</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/cribado`}>Vibrating Screen</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/Molinos`}>Mill</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/Flotacion`}>Flotation</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/Bombeo`}>Pumping</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/filtracion`}>Filters</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/Refacciones`}>Spare parts</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/Plantas`}>Complete Plants</Link></li>
                                    </ul>
                                </li>
                                <li><Link to={`${process.env.PUBLIC_URL}/contact-us-en`}>Contact</Link> </li>
                            </ul>
                            </nav>
                            <div className="offcanvas-widget-area">
                                <div className="off-canvas-contact-widget">
                                <div className="header-contact-info">
                                    <ul className="header-contact-info__list">
                                    <li><i className="ion-android-phone-portrait" /> <a href="tel://12452456012">+52 (656) 199-4312 </a></li>
                                    <li><i className="ion-android-mail" /> <a href="mailto:info@yourdomain.com">mcastillo@promival.com.mx</a></li>
                                    </ul>
                                </div>
                                </div>
                                {/*Off Canvas Widget Social Start*/}
                                <div className="off-canvas-widget-social">
                                <a href="#/" title="Facebook"><i className="fa fa-facebook" /></a>
                                <a href="#/" title="Twitter"><i className="fa fa-twitter" /></a>
                                <a href="#/" title="LinkedIn"><i className="fa fa-linkedin" /></a>
                                <a href="#/" title="Youtube"><i className="fa fa-youtube-play" /></a>
                                <a href="#/" title="Vimeo"><i className="fa fa-vimeo-square" /></a>
                                </div>
                                {/*Off Canvas Widget Social End*/}
                            </div>
                        </div>
                    </div>
                </div>
                {/*=======  End of offcanvas mobile menu  =======*/}

            </div>
        )
    }
}

export default MobileMenuEn;