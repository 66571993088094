import React, { Component } from 'react';

class AboutTextEn extends Component{

    render(){
        return(
            <div>
                {/*====================  about text area ====================*/}
                <div className="about-text-area grey-bg section-space--inner--120">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className="video-cta-content">
                                <h3 className="video-cta-content__title">Promival</h3>
                                <p className="video-cta-content__text">We are a response to the new economic situation for small and medium miners, dedicated to providing the necessary equipment for the benefit from the conceptual phase, the selection of suppliers and the logistics necessary to put them in the plants both in operation and under construction.</p>
                                <a href={`${process.env.PUBLIC_URL}/nosotros`} className="ht-btn ht-btn--round">MORE</a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="cta-video-image__image">
                                    <img src="assets/img/backgrounds/nosotros2.jpg" className="img-fluid" alt="" />
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
                {/*====================  End of about text area  ====================*/}
            </div>
        )
    }
}


export default AboutTextEn;