import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class ServiceTabTwo extends Component{

    render(){
        
        /* service tab menu */
        let serviceTabMenuData = [
            {iconName: 'flaticon-002-welding', tabMenuName: 'Trituración'},
            {iconName: 'flaticon-004-walkie-talkie', tabMenuName: 'Cribado'},
            {iconName: 'flaticon-015-cart', tabMenuName: 'Molienda'},
            {iconName: 'flaticon-010-tank-1', tabMenuName: 'Flotación'}
        ];

        let serviceTabMenuDatalist = serviceTabMenuData.map((val, i)=>{
            return(
                <Tab key={i}>  <span className="icon"><i className={val.iconName} /></span> <span className="text">{val.tabMenuName}</span></Tab>
            )
        });

        
        /* service tab content */
        
        let serviceTabContentData = [
            {bgUrl: "trituradora.jpg", contentTitle: 'Trituración', contentDesc: 'Es una máquina que procesa un material de forma que produce dicho material con trozos de un tamaño menor al tamaño original.', serviceLink: 'trituracion'},
            {bgUrl: "cribado.jpg", contentTitle: 'Cribado', contentDesc: 'Es una técnica que se usa para separar los componentes de una mezcla de sólidos de distinto tamaño o de sólidos no disueltos en un liquido, a través de un tamiz (colador), que es una malla o tela muy tupida.', serviceLink: 'cribado'},
            {bgUrl: "molinos.jpg", contentTitle: 'Molienda', contentDesc: 'Proceso mediante el cual se reduce el tamaño del material mineralizado a menos de 0,2 milímetros, de manera que sea adecuado para la flotación.', serviceLink: 'molinos'},
            {bgUrl: "flotacion.jpg", contentTitle: 'Flotación', contentDesc: 'La flotación es un proceso físico-químico de separación de minerales o compuestos finamente molidos, basados en las propiedades superficiales de los minerales (mojabilidad), que hace que un mineral o varios se queden en una fase o pasen a otra.', serviceLink: 'flotacion'}
        ];

        let serviceTabContentDatalist = serviceTabContentData.map((val, i)=>{
            return(
                <TabPanel key={i}>
                    <div className="service-tab__single-content-wrapper" style={{ backgroundImage: `url(assets/img/backgrounds/${val.bgUrl})` }}>
                        <div className="service-tab__single-content">
                            <h3 className="service-tab__title">{val.contentTitle}</h3>
                            <p className="service-tab__text">{val.contentDesc}</p>  
                            <a href={`${process.env.PUBLIC_URL}/${val.serviceLink}`} className="see-more-link">Ver más</a>
                        </div>
                    </div>
                </TabPanel>
            )
        });

        return(
            <div>

                {/*====================  service tab area ====================*/}
                <div className="service-tab-area section-space--inner--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* section title */}
                                <div className="section-title-area text-center section-space--bottom--50">
                                    <h2 className="section-title">Maquinaria       </h2>
                                    <p className="section-subtitle">Contamos con la última tecnología en cuestión de maquinaria para la minería.</p>
                                </div>

                            </div>
                            <div className="col-lg-12">
                                {/* service tab wrapper */}
                                
                                <div className="service-tab-wrapper">
                                <Tabs>
                                    <div className="row no-gutters">
                                        <div className="col-md-4">
                                            <TabList>
                                                <div className="service-tab__link-wrapper">
                                                    {serviceTabMenuDatalist}
                                                </div>
                                            </TabList>
                                        </div>

                                        <div className="col-md-8">
                                            {serviceTabContentDatalist}
                                        </div>
                                    </div>
                                </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of service tab area  ====================*/}
            </div>
        )
    }
}

export default ServiceTabTwo;