import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import HomeThree from './HomeThree';
import Home from './Home'
import About from './pages/Nosotros';
import Us from './pages/Us';
import Trituracion from './service/Trituracion';
import Crusher from './service/Crusher';
import Cribado from './service/Cribado';
import Flotacion from './service/Flotacion';
import Giratoria from './service/Giratoria';
import Bombeo from './service/Bombeo';
import Filtracion from './service/Filtracion';
import Molinos from './service/Molinos';
import Planta from './service/Plantas';
import Rotary from './service/Rotary';
import Jaw from './service/Jaw';
import Quijadas from './service/Quijadas';
import Barras from './service/Barras';
import Impacto from './service/Impacto';
import Roll from './service/Roll';
import Impact from './service/Impact';
import Hammer from './service/Hammer';
import Bolas from './service/Bolas';
import Cono from './service/Cono';
import Screen from './service/Screen';
import Cone from './service/Cone';
import Espesamiento from './service/Espesamiento';
import Laboratorio from './service/Laboratorio';
import Secadores from './service/Secadores';
import Martillos from './service/Martillos';
import Services from './service/Maquinaria';
import Equipment from './service/Equipment';
import Rodillos from './service/Rodillos';
import ManejoMateriales from './service/ManejoMateriales';
import Guijarro from './service/Guijarro';
import Raymond from './service/Raymond';
import TrituradoraMartillos from './service/TrituradoraMartillos';
import Refacciones from './service/Refacciones';
import Concentrados from './service/Concentrados';
import ServiceDetailsLeftSidebar from './service/ServiceDetailsLeftSidebar';
import ServiceDetailsRightSidebar from './service/ServiceDetailsRightSidebar';
import Projects from './project/Projects';
import ProjectDetails from './project/ProjectDetails';
import BlogLeftSidebar from './blog/BlogLeftSidebar';
import BlogRightSidebar from './blog/BlogRightSidebar';
import BlogDetailsLeftSidebar from './blog/BlogDetailsLeftSidebar';
import BlogDetailsRightSidebar from './blog/BlogDetailsRightSidebar';
import Contact from './pages/Contact';
import ContactEn from './pages/ContactEn';
import PageNotFound from './pages/404';
import NoMAtch from './pages/404';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeThree}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Home`} component={Home}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/nosotros`} component={About}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Trituradora-de-rodillos`} component={Rodillos}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/us`} component={Us}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/trituracion`} component={Trituracion}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Crusher-Rotary`} component={Rotary}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Crusher`} component={Crusher}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Crusher-Jaw`} component={Jaw}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Vibrating-Screen`} component={Screen}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Crusher-Cone`} component={Cone}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Equipo-de-Laboratorio`} component={Laboratorio}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Secado-de-concentrados`} component={Secadores}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Espesamiento-y-Filtrado`} component={Espesamiento}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Manejo-de-Materiales`} component={ManejoMateriales}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Concentracion`} component={Concentrados}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Molino-de-guijarro`} component={Guijarro}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Molino-Raymond`} component={Raymond}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Giratoria`} component={Giratoria}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Trituradora-Martillos`} component={TrituradoraMartillos}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/cribado`} component={Cribado}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/flotacion`} component={Flotacion}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Quijadas`} component={Quijadas}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Bolas`} component={Bolas}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Impacto`} component={Impacto}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Roll-Crusher`} component={Roll}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Vertical-impact-crushers`} component={Impact}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Hammer-crushers`} component={Hammer}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Barras`} component={Barras}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/bombeo`} component={Bombeo}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/plantas`} component={Planta}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Martillos`} component={Martillos}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/cono`} component={Cono}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/filtracion`} component={Filtracion}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/refacciones`} component={Refacciones}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/molinos`} component={Molinos}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/maquinaria`} component={Services}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/Equipment`} component={Equipment}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/service-details-left-sidebar`} component={ServiceDetailsLeftSidebar}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/service-details-right-sidebar`} component={ServiceDetailsRightSidebar}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/projects`} component={Projects}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/project-details`} component={ProjectDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-left-sidebar`} component={BlogLeftSidebar}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-right-sidebar`} component={BlogRightSidebar}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-details-left-sidebar`} component={BlogDetailsLeftSidebar}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-details-right-sidebar`} component={BlogDetailsRightSidebar}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact-us`} component={Contact}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact-us-en`} component={ContactEn}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/404`} component={PageNotFound}/>
                    <Route component={NoMAtch} />
                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));

serviceWorker.register();