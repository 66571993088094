import React, {Component} from 'react';
import NavBarEn from './components/NavBarEn';
import Footer from './components/Footer';
import SliderEn from './components/SliderEn';
import AboutTextEn from './components/AboutTextEn';
import ServiceTabTwoEn from './components/ServiceTabTwoEn';
import MobileMenuEn from './components/MobileMenuEn';
import FooterEn from './components/FooterEn';

class Home extends Component{
    render(){
        
        return(
            <div>
                
                {/* Navigation bar */}
                <NavBarEn/>
                
                {/* Hero slider */}
                <SliderEn/>
                
                {/* About text */}
                <AboutTextEn/>
                
                {/* Service Tab */}
                <ServiceTabTwoEn/>
                
                {/* Footer */}
                <FooterEn/>

                {/* Mobile Menu */}
                <MobileMenuEn/>

            </div>
        )
    }
}


export default Home;