import React, {Component} from 'react';
import FeatureIconEn from '../components/FeatureIconEn';
import TeamMemberGrid from '../components/TeamMemberGrid';
import FooterEn from '../components/FooterEn';
import NavBarEn from '../components/NavBarEn';
import MobileMenuEn from '../components/MobileMenuEn';
import TeamMemberGridEn from '../components/TeamMemberGridEn';
class Us extends Component{

    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
      }
     
      openModal () {
        this.setState({isOpen: true})
      }

    render(){
        return(
            <div>

                {/* Navigation bar */}
                <NavBarEn/>
                
                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Us</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--top--120">
                {/*About section start*/}
                <div className="about-section section-space--inner--bottom--120">
                <div className="container">
                    <div className="row row-25 align-items-center">
                    <div className="col-lg-6 col-12 mb-30">
                        <div className="about-image-two">
                        <img src="assets/img/about/about-4.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 mb-30">
                        <div className="about-content-two">
                        <h3>Welcome to Promival</h3>
                        <h1>Vision</h1>
                        <p>Deliver equipment and spare parts to small and medium miners in cost and time that allows them to maximize their return on investment.</p>
                        <a href="maquinaria" className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">Equipment</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*About section end*/}
                
                {/* Feature Icon */}
                <FeatureIconEn background = "grey-bg"/>

                {/*About section start*/}
                <div className="about-section section-space--inner--120">
                <div className="container">
                    <div className="about-wrapper row">
                    <div className="col-sm-6 col-12 order-1 order-lg-2">
                        <div className="about-image about-image-1">
                        <img src="assets/img/about/nosotros4.png" alt="" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-12 order-2 order-lg-3">
                        <div className="about-image about-image-2">
                        <img src="assets/img/about/nosotros5.png" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 order-3 order-lg-1">
                        <div className="about-content about-content-1">
                        <h1><span>Mision</span></h1>
                        <p>Based on the requirements of small and medium-sized miners, we select both international and national suppliers and / or manufacturers and companies dedicated to logistics to offer them at the best cost that obtains benefits for them and for PROMIVAL.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 order-4">
                        <div className="about-content about-content-2">
                        <h1><span>Who we are</span></h1>
                        <p>A group with personnel with more than 30 years of experience in the selection and sale of process equipment for the benefit of metallic and non-metallic minerals without being exclusive to a brand in the Mexican market.</p>
                        <a href={`${process.env.PUBLIC_URL}/contact-us`} className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">Contact</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*About section end*/}
                
                
                {/* Team member */}
                <TeamMemberGridEn/>
                
                </div>


                {/* Footer */}
                <FooterEn/>

                {/* Mobile Menu */}
                <MobileMenuEn/>

            </div>
        )
    }
}


export default Us;