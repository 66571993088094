import React, { Component } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";
class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);

    this.mobileMenuElement = React.createRef();
  }

  activeMobileMenu = () => {
    this.mobileMenuElement.current.toggleMobileMenu();
  };

  handleScroll() {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  }

  componentDidMount() {
    this.mount = true;
    const el = document.querySelector("nav");
    this.setState({ top: el.offsetTop, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? (document.body.style.paddingTop = `${this.state.height}px`)
      : (document.body.style.paddingTop = 0);
  }

  componentWillUnmount() {
    this.mount = false;
  }

  render() {
    return (
      <div>
        {/*====================  header area ====================*/}
        <div
          className={`header-area header-sticky header-sticky--default ${
            this.state.scroll > this.state.top ? "is-sticky" : ""
          }`}
        >
          <div className="header-area__desktop header-area__desktop--default">
            {/*=======  header info area  =======*/}
            <div className="header-info-area">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="header-info-wrapper align-items-center">
                      {/* logo */}
                      <div className="logo">
                        <Link to={`${process.env.PUBLIC_URL}/`}>
                          <img
                            src="assets/img/logo/logo-lateral.png"
                            className="img-fluid"
                            alt="Logo"
                          />
                        </Link>
                      </div>
                      {/* header contact info */}
                      <div className="header-contact-info">
                        <div className="header-info-single-item">
                          <div className="header-info-single-item__icon">
                            <i className="zmdi zmdi-smartphone-android" />
                          </div>
                          <div className="header-info-single-item__content">
                            <h6 className="header-info-single-item__title">
                              Teléfonos
                            </h6>
                            <p href = "" className="header-info-single-item__subtitle">
                              +52 (656) 552 7143
                            </p>
                          <img
                            src="assets/img/logo/whats.png"
                            /> 
                             <p className="header-info-single-item__subtitle">
                              +52 (656) 199-4312
                            </p>
                          </div>
                          
                        </div>
                        <div className="header-info-single-item">
                          <div className="header-info-single-item__icon">
                            <i className="zmdi zmdi-home" />
                          </div>
                          <div className="header-info-single-item__content">
                            <h6 className="header-info-single-item__title">
                              Dirección
                            </h6>
                            <p className="header-info-single-item__subtitle">
                              Calle Valle de las Torres No. 8598,<br></br>
                              Colonia Valle del Bravo Cd Juarez<br></br>{" "}
                              Chihuahua CP: 32695.
                            </p>
                          </div>
                        </div>
                        <div className="header-info-single-item__content">
                            <h6 className="header-info-single-item__title">
                              Idioma
                            </h6>
                            <p className="header-info-single-item__subtitle">
                              <a href = "/">Español</a>{" / "}{" "}
                              <a href = "/Home">English</a><br></br>{" "}
                            </p>
                          </div>
                      </div>
                      {/* mobile menu */}
                      <div className="mobile-navigation" >
                        <p className="header-info-single-item__subtitle">
                          <a href = "/">Español</a>{" / "}
                          <a href = "/Home">English</a>
                        </p>
                      </div>
                      <div
                        className="mobile-navigation-icon"
                        id="mobile-menu-trigger"
                        onClick={this.activeMobileMenu}
                      >
                        <i />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header info area =======*/}
            {/*=======  header navigation area  =======*/}
            <div className="header-navigation-area default-bg">
              <div className="container">
                <div className="row">
                <div className="col-lg-12">
                    {/* header navigation */}
                    <div className="header-navigation header-navigation--header-default position-relative">
                      <div className="header-navigation__nav ">
                        <nav>
                          <ul >
                            <li>
                              <Link to={`${process.env.PUBLIC_URL}/`}>
                                {" "}
                                INICIO{" "}
                              </Link>
                            </li>
                            <li>
                              <Link to={`${process.env.PUBLIC_URL}/nosotros`}>
                                NOSOTROS
                              </Link>
                            </li>

                            <li className="has-children has-children--multilevel-submenu">
                              <Link to={`${process.env.PUBLIC_URL}/maquinaria`}>
                                MAQUINARIA
                              </Link>
                              <ul className="submenu">
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/trituracion`}
                                  >
                                    Trituración
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/cribado`}
                                  >
                                    Cribado
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/Molinos`}
                                  >
                                    Molinos
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/flotacion`}
                                  >
                                    Flotación
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/Bombeo`}
                                  >
                                    Bombeo
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/filtracion`}
                                  >
                                    Filtrado
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/refacciones`}
                                  >
                                    Refacciones
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/Plantas`}
                                  >
                                    Plantas
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                                CONTACTO
                              </Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                </div>
                </div>
              </div>
            </div>
            {/*=======  End of header navigation area =======*/}
          </div>
        </div>
        {/*====================  End of header area  ====================*/}

        {/* Mobile Menu */}
        <MobileMenu ref={this.mobileMenuElement} />
      </div>
    );
  }
}

export default NavBar;
