import React, {Component} from 'react';
import NavBar from './components/NavBar';
import HeroSliderThree from './components/HeroSliderThree';
import AboutText from './components/AboutText';
import ServiceTabTwo from './components/ServiceTabTwo';
import TestimonialSlider from './components/TestimonialSlider';
import ProjectImageGallery from './components/ProjectImageGallery'
import BlogGridTwo from './components/BlogGridTwo';
import BrandLogoSlider from './components/BrandLogoSlider';
import Footer from './components/Footer';
import MobileMenuEn from './components/MobileMenuEn';

class HomeThree extends Component{
    render(){
        
        return(
            <div>
                
                {/* Navigation bar */}
                <NavBar/>
                
                {/* Hero slider */}
                <HeroSliderThree/>
                
                {/* About text */}
                <AboutText/>
                
                {/* Service Tab */}
                <ServiceTabTwo/>
                
                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenuEn/>

            </div>
        )
    }
}


export default HomeThree;