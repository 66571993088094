import React, {Component} from 'react';
import NavBarEn from '../components/NavBarEn';
import FooterEn from '../components/FooterEn';
import MobileMenuEn from '../components/MobileMenuEn';
import SidebarEn from './components/SidebarEn';
import GaleriaQuijadas from './components/GaleriaQuijadas';
class Jaw extends Component{
    render(){

        return(
            <div>
                {/* Navigation bar */}
                <NavBarEn/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Jaw crushers</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href={`${process.env.PUBLIC_URL}/maquinaria`}>Equipment</a></li>
                                        <li>Jaw crushers</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--120">
                    {/*Service section start*/}
                    <div className="service-section">
                        <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-12 order-1">
                            <div className="service-details">
                                {/* service gallery */}
                                <GaleriaQuijadas/>
                                <div className="content section-space--top--30">
                                <div className="row">
                                    <div className="col-12">
                                    <h2>Jaw crushers</h2>
                                    <a href={`${process.env.PUBLIC_URL}/contact-us-en`} className="ht-btn ht-btn--round">Contact</a>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-12 order-2">
                                <SidebarEn/>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/*Service section end*/}
                    </div>


                {/* Footer */}
                <FooterEn/>

                {/* Mobile Menu */}
                <MobileMenuEn/>

            </div>
        )
    }
}


export default Jaw;