import React, { Component } from 'react';

class AboutText extends Component{

    render(){
        return(
            <div>
                {/*====================  about text area ====================*/}
                <div className="about-text-area grey-bg section-space--inner--120">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className="video-cta-content">
                                <h3 className="video-cta-content__title">Promival</h3>
                                <p className="video-cta-content__text">Somos una respuesta ante la nueva situación de la economía para los pequeños y medianos mineros, dedicada a proveer el equipo necesario para el beneficio desde la fase conceptual, la selección de proveedores y la logística necesaria para ponerlos en las plantas tanto en operación como en construcción.</p>
                                <a href={`${process.env.PUBLIC_URL}/nosotros`} className="ht-btn ht-btn--round">CONOCENOS</a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="cta-video-image__image">
                                    <img src="assets/img/backgrounds/nosotros2.jpg" className="img-fluid" alt="" />
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
                {/*====================  End of about text area  ====================*/}
            </div>
        )
    }
}


export default AboutText;