import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';

class Contact extends Component{
    state = {
        contact: {
            name: '',
        email: '',
        subject: '',
        honeypot: '', // if any value received in this field, form submission will be ignored.
        message: '',
        replyTo: '@', // this will set replyTo of email to email address entered in the form
        accessKey: '84ec4d45-e874-419b-9dca-6ae4b77433c4'
        },
        response: {
            type: '',
            message: ''
        }
        
    };

    handleChangeName(event) {
        this.setState({contact: {name: event.target.value}});
    }

    handleChangeEmail(event) {
        this.setState({email: event.target.value});
    }

    handleChangeEmpresa(event) {
        this.setState({subject: event.target.value});
    }

    handleChangePhone(event) {
        this.setState({phone: event.target.value});
    }

    handleChangeMessage(event) {
        this.setState({message: event.target.value});
    }

    componentDidMount(){
        console.log(this.state.contact);
    }

    async handleSubmit(e){
        e.preventDefault();
        this.setState({
            response: {
                type: 'success',
                message: 'Gracias por contactarnos, responderemos a la brevedad.'
            }
        });
        alert("Gracias por contactarnos, responderemos a la brevedad.");
        /*
        try {
            const res = await fetch('https://api.staticforms.xyz/submit', {
              method: 'POST',
              body: JSON.stringify(this.state.contact),
              headers: { 'Content-Type': 'application/json' }
            });
      
            const json = await res.json();
      
            if (json.success) {
               this.setState({
                    response: {
                        type: 'success',
                        message: 'Thank you for reaching out to us.'
                    }
                });
            } else {
                this.setState({
                    response: {
                        type: 'error',
                        message: json.message
                    }
                });
            }
          } catch (e) {
            console.log('An error occurred', e);
            this.setState({
                response: {
                    type: 'error',
                    message: 'An error occured while submitting the form'
                }
            });
          }*/
    }

    validar(){
        alert("Mensaje Enviado");
    }


    render(){
        return(
            <div>
                {/* Navigation bar */}
                <NavBar/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Contáctanos</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>Contáctanos</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                {/*====================  content page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                {/*Contact section start*/}
                <div className="conact-section">
                    <div className="container">
                    <div className="row section-space--bottom--50">
                        <div className="col">
                            <div className="contact-map">
                                <iframe title="google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2402.286434313776!2d-106.39930646424588!3d31.62437409904592!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86e76774a3204549%3A0x53ee898a1b3937b3!2sValle%20de%20las%20Torres%208598%2C%20Cd%20Ju%C3%A1rez%2C%20Chih.!5e0!3m2!1ses!2smx!4v1600841208117!5m2!1ses!2smx" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-12">
                        <div className="contact-information">
                            <h3>Información de Contacto</h3>
                            <ul>
                                <li>
                                    <span className="icon"><i className="ion-android-map" /></span>
                                    <span className="text"><span> 

                                                        Calle Valle de las Torres No. 8598,

                                                        Colonia Valle del Bravo

                                                        Cd Juarez Chihuahua

                                                        CP: 32695.</span></span>
                                </li>
                                <li>
                                    <span className="icon"><i className="ion-ios-telephone-outline" /></span>
                                    <span className="text"><a href="tel:6565527143">+52 (656) 552 7143</a></span>
                                </li>
                                <li>
                                    <span className="icon"><i className="ion-ios-email-outline" /></span>
                                    <span className="text"><a href="mailto:info@example.com">mcastillo@promival.com.mx</a><a href="mailto:info@example.com">alonso.valle@proival.com.mx</a></span>
                                </li>
                            </ul>
                        </div>
                        </div>
                        <div className="col-lg-8 col-12">
                        <div className="contact-form">
                            <h3>Deja tu mensaje</h3>
                            <div className= "col-12"><h4 style={{color:"#4DB604"}}>{this.state.response.message}</h4></div>
                            <form id="contact-form" action="https://api.staticforms.xyz/submit" method="post" >
                            <input type="text" name="honeypot" style={{display:"none"}}/>
                            <input type="hidden" name="accessKey" value="46600213-41bf-42c7-bf3e-0ad967c1038f" />
                            <div className="row row-10">
                                
                                <div className="col-md-6 col-12 section-space--bottom--20"><input required name="name" type="text" placeholder="Tu Nombre" /></div>
                                <div className="col-md-6 col-12 section-space--bottom--20"><input required name="email" type="email" placeholder="Tu Email" /></div>
                                <div className="col-md-6 col-12 section-space--bottom--20"><input required name="subject" type="text" placeholder="Asunto" /></div>
                                <div className="col-md-6 col-12 section-space--bottom--20"><input required name="$empresa" type="text" placeholder="Empresa"/></div>
                                <div className="col-md-6 col-12 section-space--bottom--20"><input required name="$equipo" type="text" placeholder="Equipo Solicitado"/></div>
                                <div className="col-md-6 col-12 section-space--bottom--20"><input required name="phone" type="text" placeholder="Teléfono" /></div>
                                <div className="col-12"><textarea name="message" placeholder="Tu Mensaje" required defaultValue={""} /></div>
                                <input type="hidden" name="redirectTo" value="https://www.promival.com.mx/contact-us"></input>
                                <div className="col-12"  ></div>

                            </div>
                            <button  type="submit" onClick={() => this.validar()}><span > Enviar Mensaje</span></button>
                            </form>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/*Contact section end*/}
                </div>
                {/*====================  End of content page content  ====================*/}
                
                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default Contact;